import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import LogInModal from '../components/internal/log-in-modal';

/**
 * @description Renders hamburger menu content.
 */
class HamburgerContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen ? this.props.isOpen : false,
            isModalOpen: false
        }
    }

    componentDidMount () {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = event => {
        if ((!event.target.closest('header') || event.target.tagName === 'A') && this.props.isOpen) {
            this.props.close();
        }

        if(event.target.classList.contains('logo-nav') && this.props.isOpen){
            this.props.close();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: nextProps.isOpen });
        }
    }

    handleSearchKeyDown (event) {
        if (event.key === 'Enter') {
            this.props.close();
            navigate(`/search/?q=${event.target.value}`);
        }
    }

    toggleModal () {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    render() {
        const styles = {
            container: {
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                overflowY: 'scroll',
                overflowX: 'hidden',
                height: this.state.isOpen ? '100vh' : 0,
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                opacity: 1,
                color: '#fafafa',
                zIndex: 2,
            },
            menuList: {
                paddingTop: '6.2rem',
            }
        };

        return (
            <div style={styles.container}>
                {
                    this.state.isOpen ?
                        <div style={styles.menuList} className="mobile-bg">
                            <div className="container">
                                <input
                                    type="text"
                                    onKeyDown={event => this.handleSearchKeyDown(event)}
                                    className="form-control"
                                    aria-describedby="search" />
                                <div className="menu-items clearfix">
                                    {this.props.navigation}
                                </div>
                                <button className="btn-log-in btn btn-sm" onClick={() => this.toggleModal()}>Log in</button>
                                <a className="btn-create btn btn-sm" href="https://apply.commonapp.org/createaccount" target="_blank" rel="noopener noreferrer">Create an Account</a>
                            </div>
                        </div> : null
                }
                <LogInModal show={this.state.isModalOpen} handleClose={() => this.toggleModal()} />
            </div>
        );
    }
}

HamburgerContent.propTypes = {
    isOpen: PropTypes.bool,
    navigation: PropTypes.array.isRequired
};

export default HamburgerContent;
