import React, { Component } from 'react';
import { Link } from 'gatsby';

/**
 * @description Wrapper around MainMenu, SidebarMenu, and FooterMenu components
 */
export default class Menu extends Component  {


    constructor(props){
        super(props);
        this.state = {
          hover: false,
          hoverId: null,
          mobileView: true
        }
    }

    componentDidMount () {
        this.getViewportWidth();
        window.addEventListener('resize', this.getViewportWidth);
    }
    

    componentWillUnmount () {
        window.removeEventListener('resize', this.getViewportWidth);
    }

    getViewportWidth = () => {
      const innerWidth = Math.min(window.innerWidth, window.screen.width);
      const mobileView = innerWidth < 1080;
      this.setState({ mobileView });
    };

    hoverOn = (id) => {
      this.setState({ hover: true, hoverId: id });
    }

    hoverOff = () => { 
      this.setState({ hover: false, hoverId: null });    
    }

    /**
     * @description Converts flat menu array into tree structure.
     * Because we can't make sure parent always is before child (cause we're sorting by weight in graphql)
     * This has to be O(n^2) + O(n log n) sorting of children
     *
     * @param edges
     * @returns {Array}
     */
    convertToTree(edges) {
        var tree = [],
            mappedArr = {},
            arrElem,
            mappedElem;

        // First map the nodes of the array to an object -> create a hash table.
        for (var i = 0, len = edges.length; i < len; i++) {
            arrElem = edges[i].node;
            mappedArr[arrElem.drupal_internal__id] = arrElem;
            mappedArr[arrElem.drupal_internal__id]['children'] = [];
        }


        for (var id in mappedArr) {
            if (mappedArr.hasOwnProperty(id)) {
                mappedElem = mappedArr[id];
                // If the element is not at the root level, add it to its parent array of children.
                if (mappedElem.parent_drupal_internal__id) {
                    mappedArr[mappedElem['parent_drupal_internal__id']]['children'].push(mappedElem);
                }
                // If the element is at the root level, add it to first level elements array.
                else {
                    tree.push(mappedElem);
                }
            }
        }

        // Sort children
        tree.forEach((treeItem) => {
            if (treeItem.children.length === 0)
                return;

            treeItem.children.sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0))
        });

        return tree;
    }


    /**
     * @description Render single menu item
     * @param node
     * @param keyOptional
     * @returns {*}
     */
    renderLink(node, keyOptional) {
        const className = (node.children && node.children.length > 0) ? 'nav-link-expandable' : '';
        const activeClassName = (node.children && node.children.length > 0) ? '' : 'nav-link-active';

        const key = ((keyOptional) ? keyOptional : node.drupal_internal__id) * 198;

        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(node.alias);
        
        if (internal) {  
            if (this.state.mobileView) {
                return (<Link activeClassName={activeClassName} className={className} key={key} to={node.alias}>{node.title}</Link>);
            }

            if(node.children && node.children.length === 0)
            {
                return (<Link activeClassName={activeClassName} className={className} key={key} to={node.alias}>{node.title}</Link>);
            }

            return (
                <Link onMouseEnter={() => this.hoverOn(node.drupal_internal__id)} activeClassName={activeClassName} className={className} key={key} to={node.alias}>{node.title}</Link>
            );
        }

        return (
            <a className={className} key={key} target="_blank" rel="noopener noreferrer" href={node.alias}>{node.title}</a>
        );
    }
};
