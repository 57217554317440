import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * @description Renders the hamburger menu button.
 */
class HamburgerButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen ? this.props.isOpen : false,
            color: this.props.color ? this.props.color : 'black',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: nextProps.isOpen });
        }

        if (nextProps.isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }

    handleClick() {

        if (this.props.onClick) {
            this.props.onClick();
            
        } else {
            this.setState({ isOpen: !this.state.isOpen });
        }
    }

    render() {
        const styles = {
            container: {
                position: 'absolute',
                top: 6,
                right: -5,
                zIndex: '99',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                width: '25px',
                height: '32px',
                color: 'white',
                border: 'none',
                backgroundColor: 'transparent',
                outline: 'none'
            },
            line: {
                height: '2px',
                width: '20px',
                transition: 'all 0.2s ease',
            },
            lineTop: {
                transform: this.state.isOpen ? 'rotate(45deg)' : 'none',
                transformOrigin: 'top left',
                marginBottom: '5px',
            },
            lineMiddle: {
                opacity: this.state.isOpen ? 0 : 1,
                transform: this.state.isOpen ? 'translateX(-16px)' : 'none',
            },
            lineBottom: {
                transform: this.state.isOpen ? 'translateX(-1px) rotate(-45deg)' : 'none',
                transformOrigin: 'top left',
                marginTop: '5px',
            },
        };

        return (
            <button
                type="button"
                id={"hamburger-menu-button"}
                aria-label="Toggle menu"
                style={styles.container}
                onClick={() => this.handleClick()}>
                <div style={{ ...styles.line, ...styles.lineTop }} className="hamb-line" />
                <div style={{ ...styles.line, ...styles.lineMiddle }} className="hamb-line" />
                <div style={{ ...styles.line, ...styles.lineBottom }} className="hamb-line" />
            </button>
        );
    }
}

HamburgerButton.propTypes = {
    isOpen: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func
};

export default HamburgerButton;
