import React from 'react';
import Mainmenu from './mainmenu';
import LogInModal from '../components/internal/log-in-modal';

import logo from'../images/common-app-logo-new.svg';
import logoBlack from'../images/common-app-logo-black-new.svg';
import logoWhite from'../images/common-app-logo-white-new.svg';

import searchIcon from'../images/icons/search-icon.svg';
import searchIconBlack from'../images/icons/search-icon-black.svg';
import closeSearch from'../images/icons/icon-alert-close-search.svg';

// import closeAlert from'../images/icons/alert/icon-alert-close.svg';
import signAlert from'../images/icons/alert/icon-alert-warning.png';

import {Link, navigate} from 'gatsby';
import Cookies from 'universal-cookie';



export default class Header extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props, context) {
        super(props, context);

        this.cookies = new Cookies();
        // alert(this.cookies.get('caAlertBar'));

        this.handleScroll = this.handleScroll.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.inverted = !!props.inverted;

        this.state = {
            isSearchActive: false,
            isExploreHeader: null,
            show: false,
            alert: {
                isActive: false,
                text: '',
                link: '',
                linkText: '',
            }
        };

        
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    

    /**
     *
     */
    handleScroll() {
        this.getSizes();
        this.setState({scroll: window.scrollY});
    }

    /**
     *
     */
    async componentDidMount() {
        this.getSizes();
        window.addEventListener('resize', this.getSizes);
        window.addEventListener('scroll', this.handleScroll);

        try {
            const res = await fetch('https://ebs.commonapp.xyz/api/alert'); // TMP hardcoded for testing purposes
            const { isActive, text, link, linkText } = await res.json();
            
            this.setState({ alert: { isActive, text, link, linkText }});

        } catch (error) {
            throw new Error(error);
        }
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.getSizes);
        window.removeEventListener('scroll', this.handleScroll);
    }

    getSizes = () => {
        const el = document.querySelector('.header-main');
        const compExist = document.querySelector('.above-hero');

        if(compExist)
            this.setState({compExist: true});

        this.setState({
            top: el.offsetTop,
            height: el.offsetHeight,
            isExploreHeader: !!document.querySelector('.explore-layout')
        });
    };


    /**
     * Render logo
     * @returns {*}
     */
    renderLogo() {
        return (
            <Link to={"/"}>
                <img className="logo-nav default-logo" src={this.state.scroll > this.state.top || this.inverted ? logoBlack : logo}  alt="common app logo"/>
                <img className="logo-nav mobile-logo" src={logoBlack} alt="common app logo"/>
                <img className="logo-nav white-logo" src={logoWhite} alt="common app logo"/>
            </Link>
        );
    }

    /**
     * 
     */
    componentDidUpdate() {	
        let el = document.querySelector('body');
        this.state.scroll > this.state.top ?
            el.style.paddingTop = `${this.state.height}px` :
            el.style.paddingTop = 0;
    }

    focusSearchActive () {
        this.setState({ isSearchActive: true });
    }
    focusOutSearchActive () {
        this.setState({ isSearchActive: false });
    }

    getSearchImage () {
        return (this.state.scroll > this.state.top || this.inverted || this.state.isSearchActive) ? searchIconBlack : searchIcon;
    }

    handleSearchKeyDown (event) {
        if (event.key === 'Enter') {
            navigate(`/search/?q=${event.target.value}`);
        }
    }

    closeAlertBar() {
        if (this.state.alert.isActive) {
            this.setState({ alert: { isActive: false }});
        }

        this.cookies.set('caAlertBar', false, { expires: new Date(new Date().getTime()  + 30 * 24 * 60 * 60 * 1000 ) }) // 30 days cookie
    }

    renderAlertBar() {

        if (this.cookies.get('caAlertBar')) {
            return null;
        }

        const { isActive, text, link, linkText } = this.state.alert;
        
        if (isActive) {
            return (
                <div className="caalert clearfix">
                    <div className="container">
                        <div className="caalert-inner">
                            <img className="sign-alert" src={signAlert} alt="sign alert"/>
                            <p>
                                {text}
                                <a className="caalert-link" href={link} target="_blank" rel="noopener noreferrer">
                                    {linkText}
                                </a>
                            </p>
                            {/* <a href="javascript:void(0)" onClick={() => this.closeAlertBar()}>
                                <img className="close-alert" src={closeAlert} alt="close alert"/>
                            </a> */}
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    /**
     *
     * @returns {*}
     */
    render () {

        let className = 'header-main';

        if(this.state.scroll > this.state.top)
            className += ' header-fixed'; 
        if(this.inverted && className === 'header-main')	
            className += ' header-inverted';	
        if(!this.state.compExist)
            className += ' header-transparent';
        if (this.state.isExploreHeader)
            className += ' header--explore';

         return (
             <>
                {this.renderAlertBar()}
                <header className={className}>
                    <div className="container">
                        <div className="row flex-nowrap justify-content-between align-items-center">

                            <div className="col-2 logo-holder">
                                {this.renderLogo()}
                            </div>
                            <div className={`col searching ${this.state.isSearchActive ? 'searching-active' : ''}`}>
                                <Mainmenu />
                                <div className="search-link text-white">
                                    <input
                                        className="search-link-input"
                                        aria-label="Site-wide search"
                                        type="search"
                                        placeholder="Search"
                                        onKeyDown={event =>this.handleSearchKeyDown(event)}
                                        onFocus={() => this.focusSearchActive()}
                                        onBlur={() => this.focusOutSearchActive()} />
                                    <img className="close-search" src={closeSearch} alt="close search icon"/>
                                </div>
                            </div>
                            <div className="col justify-content-end align-items-center search-login text-right">
                                <button className="log-in-btn btn btn-sm btn-white" onClick={this.handleShow}>Sign in</button>
                                <a className="btn btn-sm btn-transparent" href="https://apply.commonapp.org/createaccount">Create an account</a>
                            </div>

                            <LogInModal show={this.state.show} handleClose={() => this.handleClose()} />

                        </div>
                    </div>
                </header>
             </>
        );
    }
}
